<template lang="pug">
  .index-c-w
    .main
      MTitleI(title="案件详情" className="my-title" :caseNo="(claimDetailBy && claimDetailBy.caseNo) ? claimDetailBy.caseNo : ''")
      GeminiScrollbar(class="my-scroll-bar" autoshow)
        .info-list
          .list-item(v-for='item in infoList')
            .title {{item.title}}
            .info-box
              .info-item(v-for='item2 in item.infos')
                .i-label {{item2.label}}
                .i-value(v-if="item2.prop!='finishCaseTime'||(claimDetailBy['caseStatus']=='3'||claimDetailBy['caseStatus']=='4'||claimDetailBy['caseStatus']=='5')") {{item2.underWrite?(item2.prop === 'insuranceStartTime' ? (claimDetailBy.underWrite[item2.prop] }} - {{ claimDetailBy.underWrite['insuranceEndTime']) : claimDetailBy.underWrite[item2.prop] ):claimDetailBy[item2.prop]}}
      .back
        .cur-btn(@click="onBack") 返回
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      //infos增加underWrite字段，如果true那么去underWrite属性下去取值
      infoList: [
        {
          title: "保单信息",
          infos: [
            { label: "保单号：", value: "", prop: "policyNo" },
            {
              label: "保险方案：",
              value: "",
              prop: "insurancePlanTypeStr",
              underWrite: true,
            },
            {
              label: "保障期限：",
              value: "",
              prop: "insuranceStartTime",
              underWrite: true,
            },
            {
              label: "投保人：",
              value: "",
              prop: "applicantName",
              underWrite: true,
            },
            {
              label: "所属市残联：",
              value: "",
              prop: "belongCityDps",
              underWrite: true,
            },
            {
              label: "出单公司：",
              value: "",
              prop: "issueCompany",
              underWrite: true,
            },
            // { label: "保障项目归属：", value: "", prop: "" },
          ],
        },
        {
          title: "报案信息",
          infos: [
            { label: "报案时间：", value: "", prop: "reportTime" },
            { label: "出险被保险人：", value: "", prop: "dangerPeopleName" },
            { label: "出险时间：", value: "", prop: "dangerTime" },
            { label: "出险地点：", value: "", prop: "dangerAddress" },
            { label: "出险人姓名：", value: "", prop: "dangerPeopleName" },
            { label: "出险人身份证：", value: "", prop: "dangerPeopleIdCard" },
            { label: "出险原因：", value: "", prop: "reasonsStr" },
            { label: "事故简述：", value: "", prop: "accidentSketch" },
          ],
        },
        {
          title: "联系信息",
          infos: [
            { label: "报案人姓名：", value: "", prop: "reportPeopleName" },
            { label: "报案人电话：", value: "", prop: "reportPeopleMobile" },
            { label: "案件处理人姓名：", value: "", prop: "caseContactName" },
            { label: "案件处理人电话：", value: "", prop: "caseContactMobile" },
          ],
        },
        {
          title: "理赔信息",
          infos: [
            { label: "估损金额：", value: "", prop: "lossAssessmentAmount" },
            { label: "医疗赔款：", value: "", prop: "medicineIndemnity" },
            { label: "残疾赔款：", value: "", prop: "disabilityIndemnity" },
            { label: "死亡赔款：", value: "", prop: "deathIndemnity" },
            {
              label: "意外住院津贴赔款：",
              value: "",
              prop: "accidentHospitalizationBenefitIndemnity",
            },
            {
              label: "重大疾病赔款：",
              value: "",
              prop: "seriousDiseaseIndemnity",
            },
            {
              label: "疾病死亡赔款：",
              value: "",
              prop: "diseaseDeathIndemnity",
            },
            {
              label: "疾病住院医疗赔款：",
              value: "",
              prop: "diseaseHospitalizationMedicineIndemnity",
            },
            { label: "结案金额：", value: "", prop: "finishCaseAmount" },
            { label: "结案时间：", value: "", prop: "finishCaseTime" },
          ],
        },
      ],
    };
  },
  created() {
    let id = this.$route.query.id; //获取路由中的查询参数，传入 api 获取详情信息
    this.getClaimDetailByAction({ id });
  },
  computed: {
    ...mapState({
      claimDetailBy: (state) => state.indemnityCenter.claimDetailBy, //详情信息
    }),
  },
  methods: {
    ...mapActions(["getClaimDetailByAction"]),
    onBack() {
      //返回list
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.my-scroll-bar {
  width: 100.1%;
}
.main {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
  padding: 20px;
  .df;
  .dfc;
  .my-title {
    padding-bottom: 20px;
  }
  .back {
    text-align: center;
    padding-top: 10px;
    .cur-btn {
      display: inline-block;
    }
  }
}
.info-list {
  flex: 1;
  .list-item {
    background: transparent;
    margin-bottom: 10px;
    .title {
      background: rgba(20, 33, 102, 0.8);
      height: 36px;
      line-height: 36px;
      color: #fff;
      font-size: 14px;
      padding: 0 20px;
    }
    .info-box {
      color: #5a86d3;
      line-height: 24px;
      padding: 10px 0;
      border: 1px solid #1c2a75;
      border-top: none;
    }
    .info-item {
      .df;
      .dfac;
      .i-label {
        width: 160px;
        text-align: right;
        margin: 0 0 0 60px;
      }
    }
  }
}
</style>
